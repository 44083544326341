<template>
  <div class="row" v-if="canCreateGuideForAllOperators">
    <div class="flex md1"></div>
    <div class="flex md10">
      <va-card>
        <va-card-content>
          <div class="row">
            <div class="flex md12">
              <va-radio
                v-for="(agency, index) in agencySelectionOptions"
                :key="index"
                v-model="form.newAgency"
                :label="agencySelectionLabels[index]"
                :option="agency"/>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
  <div class="row">
    <div class="flex md1"/>
    <div class="flex md10">
      <va-card>
        <va-card-title>
          <h2 class="text-h2">Ajouter un nouveau guide</h2>
        </va-card-title>
        <va-card-content>
          <div class="row justify--center" v-if="errors.length !== 0">
            <div class="flex lg8">
              <va-alert color="danger" icon="info" class="mb-1">
                <ul>
                  <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </va-alert>
            </div>
          </div>
          <div class="row justify--center" v-if="success">
            <div class="flex lg8">
              <va-alert color="success" icon="check" class="mb-1">
                Guide ajouté avec succès. Un e-mail recapituatif a été envoyé au guide avec ses informations de connexion.
              </va-alert>
            </div>
          </div>
          <div v-if="canCreateGuideForAllOperators">
            <div class="row" v-if="form.newAgency">
              <div class="flex md12">
                <va-input v-model="form.operator.name" label="Nom de l'agence" placeholder="Nom de l'agence"/>
              </div>
            </div>
            <div class="row" v-else>
              <div class="flex md12">
                <va-select
                  searchable
                  label="Nom de l'agence"
                  v-model="form.operator.id"
                  :options="operators"
                  :track-by="(option) => option.id"
                  text-by="name"
                  value-by="id"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <va-input v-model="form.name" label="Nom du Guide" placeholder="Nom du Guide"/>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <va-input v-model="form.email" label="Email" placeholder="Email"/>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <va-input v-model="form.phone" label="Téléphone" placeholder="Téléphone"/>
            </div>
          </div>
          <div class="row">
            <div class="flex md12">
              <va-button size="large" gradient @click="submitNewGuide" :loading="loadingForm">Confirmer Guide</va-button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";

export default {
  name: "GuideCreate",
  setup () {
    const store = useStore();
    return {
      canCreateGuideForAllOperators: store.getters["canCreateGuideForAllOperators"],
    }
  },
  data () {
    return {
      errors: [],
      success: false,
      loadingForm: false,
      agencySelectionOptions: [true, false],
      agencySelectionLabels: ['Nouvelle Agence', 'Agence existante'],
      operators: [],
      form: {
        newAgency: true,
        operator: {
          name: '',
          id: 1
        },
        name: '',
        email: '',
        phone: '',
      }
    }
  },
  methods: {
    async submitNewGuide () {
      try {
        this.success = false
        this.loadingForm = true
        const response = await axios.post('/api/guide', this.form)
        this.errors = []
        this.success = true
        this.form = {
          newAgency: true,
          operator: {
            name: '',
            id: 1
          },
          name: '',
          email: '',
          phone: '',
        }
        console.log(response)
      } catch (e) {
        this.errors = Object.keys(e.response.data.errors).map(key => e.response.data.errors[key]).flat()
      } finally {
        this.loadingForm = false
      }
    }
  },
  async mounted() {
    const data = await this.axios.get('/api/operators/')
    this.operators = data.data
  },
}
</script>

<style scoped>

</style>
